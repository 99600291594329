/*----------------------------------
  Tabs
------------------------------------*/
.tab-profile {
  &>li {
    &>a {
      &>div {
        background: $light;
      }
      &.active {
        &>div {
          background: $white;
        }
      }
    }
  }
}