// Request a color level

@function color-level($color: $primary, $level: 0) {
  $color-base: if($level > 0, $black, $white);
  $level: abs($level);

  @return mix($color-base, $color, $level * 10% );
}

@function tint-color($color, $level) {
  @return mix(white, $color, $level * 10% );
}

@function shade-color($color, $level) {
  @return mix(black, $color, $level * 10% );
}


// svg background images
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
//
// Requires the use of quotes around data URIs.

@function escape-svg($string) {
  @if str-index($string, "data:image/svg+xml") {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, "url(") == 1 {
        $string: url("#{str-replace(str-slice($string, 6, -3), $char, $encoded)}");
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}