/*------------------------------------
  General
------------------------------------*/
html {
  overflow-x: hidden;
}
body {
  position: relative;
  overflow-x: hidden;
  font: {
    family: $font-primary;
  }
}
// pading top if nav fixed available
.body-nav-fixed {
  padding-top: $header-height;
}
section, .section {
  position:relative;
  z-index: 1;
}
a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: $primary;
  }
  &:focus {
    outline: none;
  }
}
button {
  &:focus {
    outline: none;
  }
}
.btn-link {
  text-decoration: none;
}
p {
  line-height: $line-height-base;
}
section, .section {
  p {
     line-height: $paragraph-line-height;
  }
}
.lead {
  margin-bottom: $lead-margin-bottom;
}
:focus { outline: none; }
figure {
  margin-bottom: 0;
}
hr {
  border-color: $gray-100;
}
[role=button] {
  cursor: pointer;
}
.font-accent {
  font-family: "Open Sans", Helvetica, sans-serif;
}

/*------------------------------------
  Headings
------------------------------------*/
.h1, .h2, .h3, .h4, .h5, .h6, .h7,
h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  color: $heading-color;
  a {
    color: $heading-color;
    &:hover {
      color: $primary;
    }
  }
}

/*------------------------------------
  Highlight Color
------------------------------------*/
::-moz-selection {
  color: $white;
  background-color: $primary;
}
::selection {
  color: $white;
  background-color: $primary;
}
.bg-primary ::-moz-selection {
  color: $primary;
  background-color: $white;
}
.bg-primary ::selection {
  color: $primary;
  background-color: $white;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
/*------------------------------------
  hr divider
------------------------------------*/
hr {
  &.divider {
    margin-left: auto;
    margin-right: auto;
    border-top-width: $hr-divider-border-width;
    border-bottom-width: $hr-divider-border-width;
    max-width: $hr-divider-max-width;
    height: $hr-divider-height;
    opacity: 0.85;
    &.double-hr {
      border-style: solid;
      height: $hr-divider-double-height;
      max-width: $hr-divider-double-max-width;
      opacity: 0.85;
    }
  }
}